// extracted by mini-css-extract-plugin
export var active = "Slideshow-module--active--a2ff7";
export var fadein = "Slideshow-module--fadein--2f7b0";
export var fadeout = "Slideshow-module--fadeout--bdf2a";
export var homeHeaderWrapper = "Slideshow-module--homeHeaderWrapper--cc6c7";
export var homeHeight = "Slideshow-module--homeHeight--1b51b";
export var homeSubtitleBig = "Slideshow-module--homeSubtitleBig--1f2d6";
export var homeTitle = "Slideshow-module--homeTitle--be923";
export var notactive = "Slideshow-module--notactive--7f040";
export var slideshow = "Slideshow-module--slideshow--ba7f5";
export var subTitle = "Slideshow-module--subTitle--8860c";