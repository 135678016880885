import React from 'react';
import Header from '../components/Header101/Header';
import Footer from '../components/Footer6/Footer';

const Layout = ({ children }) => {
  return (
    <div>
      <Header
        title="肉のながおか"
        logoImage="/nagaoka2024/font-logo.png"
        navItems={[
          {
            id: 1,
            name: 'SNS',
            dropdown: true,
            subItems: [
              {
                id: 1,
                name: 'instagram',
                link: 'https://instagram.com/beef.wine.nagaoka/',
              },
              {
                id: 2,
                name: 'LINE公式アカウント',
                link: 'https://lin.ee/XNe7xF7',
              },
            ],
          },
          {
            id: 2,
            name: 'コラム',
            link: '/pages',
            dropdown: false,
          },
          {
            id: 2,
            name: '公式ネットショップ',
            link: 'https://nikunagaoka.base.shop/',
            dropdown: false,
          },
          {
            id: 3,
            name: '営業時間・アクセス',
            link: '/access',
            dropdown: false,
          },
          {
            id: 4,
            name: 'メニュー',
            link: '/menu',
            dropdown: true,
            subItems: [
              { id: 1, name: 'コース料理', link: '/menu#コース' },
              { id: 3, name: 'デザート・お土産', link: '/dessert' },
              { id: 4, name: 'お弁当', link: '/bento' },
              { id: 5, name: 'ドックフード', link: '/menu#ドッグフード' },
            ],
          },
        ]}
      />
      {children}

      <Footer
        items={[
          {
            categoriesname: '肉のながおか',
            categories: [
              { name: 'アクセス', link: '/access' },
              { name: 'コラム一覧', link: '/pages' },
              { name: 'メニュー', link: '/menu' },
              { name: 'デザート・お土産', link: '/dessert' },
              { name: 'お弁当', link: '/bento' },
              { name: 'ドックフード', link: '/menu#ドッグフード' },
            ],
          },
          {
            categoriesname: '概要',
            categories: [
              { name: '代表挨拶', link: '/greeting' },
              { name: '当店について', link: '/about' },
              {
                name: 'プライバシーポリシー',
                link: '/privacy',
              },
            ],
          },
          {
            categoriesname: 'ショップ',
            categories: [
              {
                name: '公式ネットショップ',
                link: 'https://nikunagaoka.base.shop/',
              },
            ],
          },
        ]}
        logo="/nagaoka2024/logo.png"
        logoAltText="肉のながおか"
        copyrightText="肉のながおか"
        logoLinkText="/"
        instagramUrl="https://instagram.com/beef.wine.nagaoka/"
        lineUrl="https://lin.ee/XNe7xF7"
      />
    </div>
  );
};

export default Layout;
