import React, { useState, useEffect } from 'react';
import * as styles from './Header.module.css';
import { Link } from 'gatsby';

// NavItem と SubItem の型を定義
type SubItem = {
  name: string;
  link: string;
};

type NavItem = {
  id: string;
  name: string;
  link: string;
  dropdown?: boolean;
  subItems?: SubItem[];
};

interface NavProps {
  title: string;
  navItems: NavItem[];
  logoImage?: string;
}

const Header: React.FC<NavProps> = ({ title, navItems, logoImage }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth <= 600;
    }
    return false;
  });

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      setIsActive(!isActive);
    }
  };

  return (
    <header className={styles.header} id="g_navi">
      <div className={styles.header__wrapper}>
        <Link to="/" className={styles.header__brand}>
          {logoImage ? (
            <img src={logoImage} alt={title} />
          ) : (
            <text className={styles.headerText}>{title}</text>
          )}
        </Link>
        <button
          onClick={handleClick}
          onKeyUp={handleKeyUp}
          className={
            isActive
              ? `${styles.header__hamburger} ${styles.active}`
              : `${styles.header__hamburger}`
          }
          aria-expanded={isActive}
          aria-label="OpenNavigationMenu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul
          className={
            isActive
              ? `${styles.header__navList} ${styles.active}`
              : styles.header__navList
          }
        >
          {navItems.map((item) => (
            <li key={item.id} className={styles.header__navItem}>
              <a
                href={item.link}
                target={item.dropdown ? '' : ''}
                onMouseEnter={() => (isMobile ? null : setActiveMenu(item.id))}
                onClick={handleClick}
              >
                {item.name}
              </a>
              {item.dropdown && (isMobile || activeMenu === item.id) && (
                <button
                  className={styles.header__subList}
                  onMouseLeave={() => (isMobile ? null : setActiveMenu(null))}
                  aria-haspopup="true"
                  aria-expanded={isMobile || activeMenu === item.id}
                  aria-controls="submenu-id"
                >
                  <ul id="submenu-id">
                    {item.subItems?.map((subItem, index) => (
                      <li key={index}>
                        <a href={subItem.link}>{subItem.name}</a>
                      </li>
                    ))}
                  </ul>
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
