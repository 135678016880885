// extracted by mini-css-extract-plugin
export var content = "AccessSection-module--content--765ae";
export var description = "AccessSection-module--description--13932";
export var featureSection = "AccessSection-module--featureSection--1f10b";
export var featureSectionInner = "AccessSection-module--featureSectionInner--bffd7";
export var featureSectionPrimary = "AccessSection-module--featureSectionPrimary--9c7e8";
export var featureSectionSecondary = "AccessSection-module--featureSectionSecondary--323df";
export var head = "AccessSection-module--head--8891c";
export var headSubtext = "AccessSection-module--headSubtext--39a6e";
export var headText = "AccessSection-module--headText--0ff37";
export var map = "AccessSection-module--map--aefdd";
export var title = "AccessSection-module--title--cb973";