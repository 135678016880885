// src/components/commons/FormattedText.tsx
import React from 'react';

type FormattedTextProps = {
  text: string;
};

const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
  const formattedText = text.split('\n').map((line, index, array) =>
    index === array.length - 1 ? (
      line
    ) : (
      <>
        {line}
        <br />
      </>
    ),
  );

  return <>{formattedText}</>;
};

export default FormattedText;
