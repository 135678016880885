// Button.tsx
import React from 'react';
import * as styles from './Button.module.css';

interface ButtonProps {
  text: string;
  ctaLink?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, ctaLink, onClick }) => {
  return (
    <a href={ctaLink} className={`${styles.button}`} onClick={onClick}>
      {text}
    </a>
  );
};

export default Button;
