import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Slideshow from '../components/Slideshow/Slideshow';
import AccessSectionRight from '../components/AccessSectionRight/AccessSectionRight';

export const Head = () => {
  return (
    <Seo
      title="アクセス"
      description="肉のながおか,〒802-0083 福岡県北九州市小倉北区江南町４−２,営業時間:11:30～15:00 17:00～23:00(L.O.22:00)"
    />
  );
};

const AccessPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="Access"
          subtitle="営業時間・アクセス"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <AccessSectionRight
          title="肉のながおか"
          description={`
        所在地	〒802-0083 福岡県北九州市小倉北区江南町４−２

        営業時間	11:30～15:00 17:00～23:00(L.O.22:00)

        お弁当ご注文受付時間	11:00〜18:00

        お弁当受取可能時間	11:30〜19:00
        
        電話番号	093-953-8242
        `}
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d555.5779318007152!2d130.88277241314105!3d33.875362743057686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c92de3639cc1%3A0x846fd257d5b66dc4!2zQmVlZiZXaW5lIOOBquOBjOOBiuOBiw!5e0!3m2!1sja!2sjp!4v1669773598036!5m2!1sja!2sjp"
        />
      </Layout>
    </div>
  );
};

export default AccessPage;
