import React, { useEffect, useRef, useState } from 'react';
import * as styles from './ScrollAnimation.module.css';

interface ScrollAnimationProps {
  children: React.ReactNode;
}

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <div
      className={`${styles.scrollAnimation} ${isVisible ? styles.visible : ''}`}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;
